import request from '@/utils/request';

const PATH = '/reservation/client';

export const getReservationClientList = (params: any) => request({
  url: PATH,
  method: 'get',
  params: {
    ...params,
    page: params.page - 1,
  },
});

export const getReservationClient = (uid: string) => request({
  url: `${PATH}/${uid}`,
  method: 'get',
});

export const getReservationClientForm = (uid: string) => request({
  url: `${PATH}/${uid}/form`,
  method: 'get',
});

export const getReservationClientPriceView = (uid: string) => request({
  url: `${PATH}/${uid}/price`,
  method: 'get',
});

export const addReservationClient = (data: any) => request({
  url: PATH,
  method: 'post',
  data,
});

export const updateReservationClient = (uid: string, data: any) => request({
  url: `${PATH}/${uid}/info`,
  method: 'put',
  data,
});

export const cancelReservationClient = (uid: string, data: any) => request({
  url: `${PATH}/${uid}`,
  method: 'put',
  data,
});
