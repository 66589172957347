import request from '@/utils/request';

const PATH = 'user/coupon';

export const getUserCouponList = (listQuery: any) => request({
  url: PATH,
  method: 'get',
  params: {
    ...listQuery,
  },
});
